import { Document, Page, PDFViewer, StyleSheet, Font, View, Text } from '@react-pdf/renderer'
import { AssortmentHeader } from '../components/assortmentPDF/AssortmentHeader'
import { AssortmentFooter } from '../components/assortmentPDF/AssortmentFooter'
import { AssortmentSummary } from '../components/assortmentPDF/AssortmentSummary'
import { formatCurrency } from '@/shared/helpers'
import { RobotoBold, RobotoRegular } from '@/fonts'
import { useAssortmentPDF } from '../hooks'
import { AssortmentFamilyMapResult, AssortmentProductTypeMapResult } from '../mappers'
import type { IDataReposicion } from '../types/Reposicion.types'
import React from 'react'
import { LoaderTemplate } from '@/shared/templates'

Font.register({
	family: 'Roboto',
	fonts: [
		{ src: RobotoRegular },
		{
			src: RobotoBold,
			fontWeight: 700
		}
	]
})

const styles = StyleSheet.create({
	viewer: { width: '100%', height: '100vh', position: 'absolute' },
	page: { padding: '30px 30px 40px 30px' },
	bolder: {
		fontFamily: 'Roboto',
		fontWeight: 'bold'
	},
	bodyContainer: {
		width: '100%',
		flexDirection: 'column',
		alignItems: 'center',
		fontSize: 12,
		marginBottom: 8
	},
	bodyItemContent: {
		width: '100%',
		marginBottom: 14
	},
	bodyItemText: {
		fontSize: '8px',
		width: '350px',
		padding: '2px',
		paddingLeft: '10%',
		marginBottom: 4,
		borderBottom: 1.5
	},
	bodyLines: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		fontSize: '8px',
		marginBottom: 4
	},
	bodyEanContent: {
		width: '14%',
		display: 'flex',
		flexDirection: 'row',
		fontSize: '9px',
		textAlign: 'left',
		alignItems: 'flex-start'
	},

	bodyFooterSeparate: {
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-start'
	},
	bodyFooter: {
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		fontSize: '8px'
	}
})

const ItemList = ({ models }: { models: Record<string, IDataReposicion[]> }) => {
	const modelList = Object.entries(models)
	const lastIndex = Object.entries(models).length - 1
	const lastReference = Object.entries(models).at(lastIndex)?.[0]

	return (
		<React.Fragment>
			{modelList.map(([reference, products]) => (
				<View key={reference} style={{ width: '100%' }}>
					{products.map((item) => (
						<React.Fragment key={item.ItemCode}>
							<View key={item.ItemCode} style={styles.bodyLines} break={true}>
								<Text x={0} y={0} style={{ width: '17%' }}>
									{item.ItemCode}
								</Text>
								<View style={styles.bodyEanContent}>
									<Text x={0} y={0}>
										{item.EAN.slice(0, -4)}
									</Text>
									<Text x={0} y={0} style={styles.bolder}>
										{item.EAN.slice(-4)}
									</Text>
								</View>
								<Text x={0} y={0} style={{ flex: 2, paddingRight: 2 }}>
									{item.Dscription}
								</Text>
								<Text x={0} y={0} style={{ width: 40, textAlign: 'center' }}>
									{item.Descripcion}
								</Text>
								<Text x={0} y={0} style={{ width: 40, textAlign: 'right' }}>
									{formatCurrency(item.PPrimera)}
								</Text>
								<Text x={0} y={0} style={{ width: 30, textAlign: 'right' }}>
									{item.Existencia}
								</Text>
								<Text x={0} y={0} style={{ width: 30, textAlign: 'right' }}>
									{item.Venta}
								</Text>
								<Text x={0} y={0} style={{ width: 30, textAlign: 'right' }}>
									{item.Buffer}
								</Text>
								<Text x={0} y={0} style={{ width: 35, textAlign: 'right' }}>
									{item.Quantity}
								</Text>
							</View>
						</React.Fragment>
					))}
					{lastReference !== reference && (
						<View style={{ width: 350, borderWidth: 1, marginBottom: 2 }} />
					)}
				</View>
			))}
		</React.Fragment>
	)
}

const AssortmentAccesoriesA = ({ data }: { data?: AssortmentProductTypeMapResult }) => {
	return (
		<>
			{data?.ACCESORIOS?.map(({ ugspCode, UGSPName, models, total }) => (
				<View key={ugspCode} style={styles.bodyItemContent} wrap={true}>
					<Text x={0} y={0} style={{ ...styles.bodyItemText, ...styles.bolder }} break={true}>
						{ugspCode} - {UGSPName}
					</Text>
					<ItemList models={models} />
					<View style={styles.bodyFooterSeparate}>
						<View style={{ borderBottom: 1.5, width: 350, marginTop: '4px' }} />
						<View style={{ borderBottom: 1.5, width: 105, marginTop: '4px' }} />
					</View>
					<View style={styles.bodyFooter}>
						<Text x={0} y={0} style={{ ...styles.bolder, marginTop: 2 }}>
							{total}
						</Text>
					</View>
				</View>
			))}
		</>
	)
}

const AssortmentAccesoriesB = ({ data }: { data?: AssortmentFamilyMapResult }) => {
	return (
		<>
			{data?.ACCESORIOS?.map(({ codes, family }) => (
				<View key={family} style={{ width: '100%', flexDirection: 'column' }}>
					<Text style={{ fontSize: 10, marginBottom: 6 }}>{family}</Text>
					{codes.map(({ ugspCode, UGSPName, models, total }) => (
						<View key={ugspCode} style={styles.bodyItemContent} wrap={true}>
							<Text style={{ ...styles.bodyItemText, ...styles.bolder }} break={true}>
								{ugspCode} - {UGSPName}
							</Text>
							<ItemList models={models} />
							<View style={styles.bodyFooterSeparate}>
								<View style={{ borderBottom: 1.5, width: 350, marginTop: '4px' }} />
								<View style={{ borderBottom: 1.5, width: 105, marginTop: '4px' }} />
							</View>
							<View style={styles.bodyFooter}>
								<Text x={0} y={0} style={{ ...styles.bolder, marginTop: 2 }}>
									{total}
								</Text>
							</View>
						</View>
					))}
				</View>
			))}
		</>
	)
}

export const AssortmentPDFPage = () => {
	const {
		loading,
		docNum,
		version,
		dataGroupedByFamily,
		dataGroupedByProduct,
		accessoriesTotalAssortment,
		garmentTotalAssortment,
		assortmentDate,
		toWarehouse,
		fromWarehouse
	} = useAssortmentPDF()

	if (loading) return <LoaderTemplate />

	return (
		<PDFViewer style={styles.viewer}>
			<Document title={`Reposicion - ${docNum}`} author='WMS'>
				<Page size='LETTER' style={styles.page} wrap={true}>
					<AssortmentHeader
						assortmentDate={assortmentDate}
						toWarehouse={toWarehouse}
						fromWarehouse={fromWarehouse}
						docNum={docNum || ''}
					/>

					<View style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
						<View style={styles.bodyContainer}>
							<Text x={0} y={0}>
								PRENDAS
							</Text>
						</View>
						<View wrap={true}>
							{dataGroupedByProduct?.PRENDA?.map(({ ugspCode, UGSPName, models, total }) => (
								<View key={ugspCode} style={styles.bodyItemContent} wrap={true}>
									<Text x={0} y={0} style={{ ...styles.bodyItemText, ...styles.bolder }}>
										{ugspCode} - {UGSPName}
									</Text>
									<ItemList models={models} />
									<View style={styles.bodyFooterSeparate}>
										<View style={{ borderBottom: 1.5, width: 350, marginTop: '4px' }} />
										<View style={{ borderBottom: 1.5, width: 105, marginTop: '4px' }} />
									</View>
									<View style={styles.bodyFooter}>
										<Text x={0} y={0} style={{ ...styles.bolder, marginTop: 2 }}>
											{total}
										</Text>
									</View>
								</View>
							))}
						</View>
					</View>

					<View style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
						<View style={styles.bodyContainer}>
							<Text x={0} y={0}>
								ACCESORIOS
							</Text>
						</View>
						<View wrap={true}>
							{version?.toLowerCase() === 'a' ? (
								<AssortmentAccesoriesA data={dataGroupedByProduct} />
							) : (
								<AssortmentAccesoriesB data={dataGroupedByFamily} />
							)}
						</View>
					</View>

					<AssortmentSummary
						garmentTotalAssortment={garmentTotalAssortment}
						accessoriesTotalAssortment={accessoriesTotalAssortment}
					/>
					<AssortmentFooter />
				</Page>
			</Document>
		</PDFViewer>
	)
}
