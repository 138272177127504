import type { IDataReposicion } from '../types/Reposicion.types'

export type AssortmentProductTypeMapResult = {
	[key in 'PRENDA' | 'ACCESORIOS']?: Array<{
		ugspCode: string
		UGSPName: string
		models: Record<string, IDataReposicion[]> // UGSPReference
		total: number
	}>
}

export const groupAssortmentByProductTypeMap = (data: Array<IDataReposicion>) => {
	const tempResult: AssortmentProductTypeMapResult = {}

	data.forEach((item) => {
		const tipoArticuloKey = item.TipoArticulo as 'PRENDA' | 'ACCESORIOS'
		const ugspCodeKey = item.UGSPCode
		const ugspReferenceKey = item.UGSPReference
		const ugspName = item.UGSPName

		// Inicializa el arreglo para el TipoArticulo si no existe
		if (!tempResult[tipoArticuloKey]) {
			tempResult[tipoArticuloKey] = []
		}

		// Busca o crea una entrada para el UGSPCode dentro del TipoArticulo
		let codeEntry = tempResult[tipoArticuloKey]!.find((code) => code.ugspCode === ugspCodeKey)
		if (!codeEntry) {
			codeEntry = {
				ugspCode: ugspCodeKey,
				UGSPName: ugspName,
				models: {},
				total: 0
			}
			tempResult[tipoArticuloKey]!.push(codeEntry)
		}

		// Inicializa el array para UGSPReference en models si no existe
		if (!codeEntry.models[ugspReferenceKey]) {
			codeEntry.models[ugspReferenceKey] = []
		}

		// Agrega el item al array correspondiente dentro de models (UGSPReference)
		codeEntry.models[ugspReferenceKey].push(item)

		// Incrementa el total para el UGSPCode sumando la Quantity del item actual
		codeEntry.total += item.Quantity
	})

	return tempResult
}
