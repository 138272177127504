import { Image, View, Text, StyleSheet } from '@react-pdf/renderer'
import { logoTheGreenCompanyImg } from '@/assets'

type Props = {
	assortmentDate: string
	fromWarehouse: string
	toWarehouse: string
	docNum: string
}

const styles = StyleSheet.create({
	headerContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-start'
	},
	replacementNumberContainer: {
		border: 1,
		width: '80px',
		borderRadius: '5px',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		flexDirection: 'column',
		padding: '5px'
	},
	replacementTableContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		fontSize: '7px',
		borderBottomWidth: 1,
		borderTopWidth: 1,
		marginTop: 14,
		marginBottom: 16,
		paddingTop: 4,
		paddingBottom: 4
	},
	bolder: {
		fontFamily: 'Roboto',
		fontWeight: 'bold'
	}
})

export const AssortmentHeader = ({ assortmentDate, toWarehouse, fromWarehouse, docNum }: Props) => {
	return (
		<View fixed={true}>
			{/*Informacion del encabezado*/}
			<View style={styles.headerContainer} fixed={true}>
				<Image
					src={logoTheGreenCompanyImg}
					source={logoTheGreenCompanyImg}
					style={{ width: '85px' }}
				/>
				<View style={{ width: '45%' }}>
					<Text x={0} y={0} style={{ fontSize: '14px', textAlign: 'center' }}>
						REPOSICIÓN DE MERCANCIA
					</Text>
					<Text x={0} y={0} style={{ fontSize: '10px' }}>
						Surtido del {fromWarehouse} al {toWarehouse}
					</Text>
					<Text x={0} y={0} style={{ fontSize: '10px' }}>
						Fecha Pedido: {assortmentDate}
					</Text>
				</View>
				<View style={styles.replacementNumberContainer}>
					<Text x={0} y={0} style={{ fontSize: '14px', color: 'red' }}>
						{docNum}
					</Text>
					<Text x={0} y={0} style={{ fontSize: '8px' }}>
						Reposición
					</Text>
				</View>
			</View>

			{/*Descripcion del encabezado*/}
			<View style={{ ...styles.replacementTableContainer, ...styles.bolder }}>
				<Text x={1} y={2} style={{ width: '17%' }}>
					ARTICULO
				</Text>
				<Text x={0} y={1} style={{ width: '14%' }}>
					EAN
				</Text>
				<Text x={0} y={0} style={{ flex: 2 }}>
					DESCRIPCIÓN
				</Text>
				<Text x={0} y={0} style={{ width: 40, textAlign: 'left', paddingHorizontal: 1 }}>
					EST. CANASTO
				</Text>
				<Text x={0} y={0} style={{ width: 40, textAlign: 'left', paddingHorizontal: 1 }}>
					PRECIO PRIMERA
				</Text>
				<Text x={0} y={0} style={{ width: 30, textAlign: 'right' }}>
					EXI
				</Text>
				<Text x={0} y={0} style={{ width: 30, textAlign: 'right' }}>
					VTA
				</Text>
				<Text x={0} y={0} style={{ width: 30, textAlign: 'right' }}>
					BUF
				</Text>
				<Text x={0} y={0} style={{ width: 35, textAlign: 'right' }}>
					SURTIDO
				</Text>
			</View>
		</View>
	)
}
