import type { IDataReposicion } from '../types/Reposicion.types'

export type AssortmentFamilyMapResult = {
	[key in 'PRENDA' | 'ACCESORIOS']?: Array<{
		family: string
		codes: Array<{
			ugspCode: string
			UGSPName: string
			models: Record<string, IDataReposicion[]> // UGSPReference
			total: number
		}>
	}>
}

export const groupAssortmentByFamiliesMap = (data: Array<IDataReposicion>) => {
	const tempResult: AssortmentFamilyMapResult = {}

	data.forEach((item) => {
		const tipoArticuloKey = item.TipoArticulo as 'PRENDA' | 'ACCESORIOS'
		const family = item.FamiliaDesc
		const ugspCodeKey = item.UGSPCode
		const ugspReferenceKey = item.UGSPReference
		const ugspName = item.UGSPName

		// Inicializa el arreglo para el TipoArticulo si no existe
		if (!tempResult[tipoArticuloKey]) {
			tempResult[tipoArticuloKey] = []
		}

		// Busca o crea una entrada para la Familia dentro del TipoArticulo
		let familyEntry = tempResult[tipoArticuloKey]!.find((entry) => entry.family === family)
		if (!familyEntry) {
			familyEntry = { family, codes: [] }
			tempResult[tipoArticuloKey]!.push(familyEntry)
		}

		// Busca o crea una entrada para el UGSPCode dentro de la Familia
		let codeEntry = familyEntry.codes.find((code) => code.ugspCode === ugspCodeKey)
		if (!codeEntry) {
			codeEntry = {
				ugspCode: ugspCodeKey,
				UGSPName: ugspName,
				models: {},
				total: 0
			}
			familyEntry.codes.push(codeEntry)
		}

		// Inicializa el array para UGSPReference en models si no existe
		if (!codeEntry.models[ugspReferenceKey]) {
			codeEntry.models[ugspReferenceKey] = []
		}

		// Agrega el item al array correspondiente dentro de models (UGSPReference)
		codeEntry.models[ugspReferenceKey].push(item)

		// Incrementa el total para el UGSPCode sumando la Quantity del item actual
		codeEntry.total += item.Quantity
	})

	return tempResult
}
