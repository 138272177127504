import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { formatDateComplete } from '@/shared/helpers'
import { format } from 'date-fns'

const styles = StyleSheet.create({
	footerContainer: {
		position: 'absolute',
		fontSize: '7px',
		bottom: '20px',
		left: '30px',
		right: '30px',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	}
})

export const AssortmentFooter = () => {
	return (
		<View style={styles.footerContainer} fixed>
			<Text
				x={0}
				y={0}
				render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
			/>
			<Text x={0} y={0}>
				{formatDateComplete(new Date())} {format(new Date(), 'HH:mm')}
			</Text>
		</View>
	)
}
