import { useEffect, useState } from 'react'
import { makeUseAxios } from 'axios-hooks'
import { useParams } from 'react-router-dom'
import axiosInstance from '@/app/services/axiosInstance.service'
import { formatStrToDateComplete, getError } from '@/shared/helpers'
import { useNotification } from '@/shared/hooks'
import { env } from '@/env'
import {
	groupAssortmentByProductTypeMap,
	AssortmentProductTypeMapResult,
	groupAssortmentByFamiliesMap,
	AssortmentFamilyMapResult
} from '../mappers'
import type { IDataReposicion } from '../types/Reposicion.types'

export type State = {
	dataGroupedByProduct?: AssortmentProductTypeMapResult
	dataGroupedByFamily?: AssortmentFamilyMapResult
	assortmentDate: string
	fromWarehouse: string
	toWarehouse: string
	accessoriesTotalAssortment: number
	garmentTotalAssortment: number
}

export const useAssortmentPDF = () => {
	const { docNum, version } = useParams<{ docNum: string; version: string }>()
	const notification = useNotification()
	const useAxios = makeUseAxios({
		axios: axiosInstance(process.env.REACT_APP_API_WMS ?? (env.REACT_APP_API_WMS as string))
	})

	const [
		{
			dataGroupedByFamily,
			dataGroupedByProduct,
			assortmentDate,
			toWarehouse,
			fromWarehouse,
			garmentTotalAssortment,
			accessoriesTotalAssortment
		},
		setData
	] = useState<State>({
		assortmentDate: '',
		toWarehouse: '',
		fromWarehouse: '',
		garmentTotalAssortment: 0,
		accessoriesTotalAssortment: 0
	})

	const [{ loading }, executeGetPdf] = useAxios<Array<IDataReposicion>>(
		{ url: '/asistente-reposicion/pdf', method: 'post' },
		{ manual: true }
	)

	useEffect(() => {
		const getData = async () => {
			try {
				const { data } = await executeGetPdf({
					data: {
						empresa: process.env.REACT_APP_SAP_COMPANY_TEST ?? env.REACT_APP_SAP_COMPANY_TEST,
						docNum
					}
				})

				console.log(data)

				const garmentTotalAssortment = data
					.filter(({ TipoArticulo }) => TipoArticulo === 'PRENDA')
					.reduce((acc, curr) => acc + curr.Quantity, 0)

				const accessoriesTotalAssortment = data
					.filter(({ TipoArticulo }) => TipoArticulo === 'ACCESORIOS')
					.reduce((acc, curr) => acc + curr.Quantity, 0)

				const fromWarehouse = data[0].Filler
				const toWarehouse = data[1].WhsCode
				const assortmentDate = formatStrToDateComplete(data[0].FechaRemision)

				const mappedByProduct = groupAssortmentByProductTypeMap(data)
				const mappedByFamily = groupAssortmentByFamiliesMap(data)

				setData((prev) => ({
					...prev,
					dataGroupedByFamily: mappedByFamily,
					dataGroupedByProduct: mappedByProduct,
					garmentTotalAssortment,
					accessoriesTotalAssortment,
					fromWarehouse,
					toWarehouse,
					assortmentDate
				}))
			} catch (error) {
				notification.error('No se obtuvo información del documento. Error:' + getError(error))
			}
		}

		getData()
	}, [])

	return {
		loading,
		version,
		dataGroupedByFamily,
		dataGroupedByProduct,
		docNum,
		assortmentDate,
		toWarehouse,
		fromWarehouse,
		garmentTotalAssortment,
		accessoriesTotalAssortment
	}
}
