import { View, Text, StyleSheet } from '@react-pdf/renderer'

type Props = {
	accessoriesTotalAssortment: number
	garmentTotalAssortment: number
}

const styles = StyleSheet.create({
	summaryContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 8 },
	summaryItem: { display: 'flex', flexDirection: 'row', fontSize: 8 },
	signatureContainer: {
		width: '100%',
		marginTop: '40px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center'
	},
	signatureItem: {
		fontSize: '10px',
		borderTop: 1,
		display: 'flex',
		justifyContent: 'center',
		width: '100px',
		marginLeft: '20px',
		marginRight: '20px',
		textAlign: 'center',
		padding: '1px'
	},
	bolder: { fontFamily: 'Roboto', fontWeight: 'bold' }
})

export const AssortmentSummary = ({
	accessoriesTotalAssortment,
	garmentTotalAssortment
}: Props) => {
	return (
		<View style={{ marginTop: 25, fontSize: 8 }} wrap={false}>
			<View style={{ ...styles.summaryContainer, marginBottom: 12 }}>
				<View style={{ ...styles.summaryItem, marginRight: 24 }}>
					<Text x={0} y={0} style={{ marginRight: 4 }}>
						PRENDAS:
					</Text>
					<Text x={0} y={0}>
						{garmentTotalAssortment}
					</Text>
				</View>

				<View style={styles.summaryItem}>
					<Text x={0} y={0} style={{ marginRight: 4 }}>
						ACCESORIOS:
					</Text>
					<Text x={0} y={0}>
						{accessoriesTotalAssortment}
					</Text>
				</View>
			</View>

			<View style={{ ...styles.summaryContainer, ...styles.bolder }}>
				<Text x={0} y={0} style={{ marginRight: 4 }}>
					TOTAL:
				</Text>
				<Text x={0} y={0}>
					{accessoriesTotalAssortment + garmentTotalAssortment}
				</Text>
			</View>

			<View style={styles.signatureContainer}>
				<Text x={0} y={0} style={{ ...styles.signatureItem, width: 150 }}>
					FECHA
				</Text>
				<Text x={0} y={0} style={{ ...styles.signatureItem, width: 100 }}>
					HORA
				</Text>
				<Text x={0} y={0} style={{ ...styles.signatureItem, width: 300 }}>
					RECIBIÓ
				</Text>
			</View>
		</View>
	)
}
